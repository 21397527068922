<template>
  <b-card header="Edit User Permissions">
    <permissions-table
      :selected-permissions.sync="selectedPermissions"
      :selected-groups.sync="selectedGroups"
      :available-permissions-grouped="availablePermissionsGrouped"
      :entity-id="$store.getters['mainEntity/getEntityId']"
      @assignPermissions="assignUserPermissions"
      @revokePermissions="revokeUserPermissions"
    />
    <back />
  </b-card>
</template>
<script>
import Back from '@/common/components/common/FormInputs/Back.vue'
import PermissionsTable from '@/common/components/Permissions/PermissionsTable.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import UserPermissions from '@/common/compositions/permissions/userPermissions'

export default {
  name: 'EditUserPermissions',
  components: {
    Back,
    PermissionsTable,
  },
  data() {
    return {
      selectedPermissions: [],
      selectedGroups: [],
      availablePermissionsGrouped: [],
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { getUserPermissionsInEntity, assignUserPermissions, revokeUserPermissions } = UserPermissions()
    return {
      assignUserPermissions, revokeUserPermissions, successfulOperationAlert, getUserPermissionsInEntity,
    }
  },
  created() {
    this.getAvailablePermissionsGrouped()
    this.getSelectedUserPermissions()
  },
  methods: {
    getAvailablePermissionsGrouped() {
      return this.getUserPermissionsInEntity(
        this.$store.getters['auth/getUserData'].username,
        this.$store.getters['mainEntity/getEntityId'],
        { scopeType: ' both,entity' },
      ).then(permissionsGrouped => {
        this.availablePermissionsGrouped = permissionsGrouped
      })
    },
    getSelectedUserPermissions() {
      this.getUserPermissionsInEntity(
        this.$route.params.username,
        this.$store.getters['mainEntity/getEntityId'],
        { scopeType: ' both,entity' },
      ).then(permissionsGroup => {
        const permissionsList = permissionsGroup.map(group => group.permissions).flat()
        this.selectedPermissions = permissionsList.map(permission => permission.name)

        this.selectedGroups = permissionsGroup.map(group => group.groupName)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
